<template>
  <main class="site-main">
    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-01">
      <router-link :to="{name: 'Product', params:{productNo: $route.params.productName}}"
                   tag="div" class="bms-relaunch-comp-v2"
                   role="link">
        <div class="feature-head">{{ getHead }}</div>
        <h1 class="page-header2" v-html="getTitle">
        </h1>
      </router-link>
    </div>
    <!--상단 타이틀 영역 e-->

    <menu3-depth
        :product-no="$route.params.productName"
        :select-tab="selectTab"
        @selected-tab="setSelectTab"
    />
    <v-fade-transition>
      <router-view
          @set-head="getHead"
          @set-name="getName"
          @set-ingredient-name="getIngredientName"
          @set-init-tab="setInitSelectTab"/>
    </v-fade-transition>
  </main>
</template>

<script>
import Menu3Depth from "@/components/Product/Menu3Depth";
// import {mapState} from 'vuex'

export default {
  name: "ProductLanding",
  components: {Menu3Depth},
  data() {
    return {
      selectTab: '-1',
    }
  },
  computed: {
    getHead() {
      // ...mapState('productMenu[this.$route.params.productName].head'),
      return this.$store.state.menu.productMenu[this.$route.params.productName].head
    },
    getName() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].name
    },
    getIngredientName() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].ingredientName
    },
    getTitle() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].title
    },
    sliceNameAndIngredientName() {
      return this.getName.split(/\|/).map((name, index) => {
        return {
          name: name,
          ingredientName: this.getIngredientName.split(/\|/)[index]
        }
      });
    }

  },
  methods: {
    setSelectTab(selectTab) {
      this.selectTab = selectTab
    },
    setInitSelectTab() {
      this.selectTab = '-1';
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <!--3depth 메뉴 영역-->
  <v-sheet elevation="6" color="#F6F4F3">
    <v-tabs
        show-arrows
        background-color="#F6F4F3"
        centered
        center-active
        slider-size="6"
        slider-color="#BE2BBB"
        height="66"
        style="max-width: 1100px; margin: 0 auto;"
        mobile-breakpoint="720"
        fixed-tabs
        optional
        v-model="selectedTab"
    >
      <v-tab
          v-for="(menu, index) in menuList"
          :key="index"
          :to="menu.href"
          v-text="menu.name"
      >
      </v-tab>
    </v-tabs>
  </v-sheet>
</template>

<script>
export default {
  name: "Menu3Depth",
  props: {
    selectTab: {
      type: String,
      default: '-1'
    }
  },
  computed: {
    selectedTab: {
      get() {
        return this.selectTab;
      },
      set(value) {
        this.$emit('selected-tab',value);
      }
    },
    menuList() {
      return [
        {
          name: '제품브로셔',
          href: {name: 'BrochureList'},
        },
        {
          name: '허가적응증 및 급여기준',
          href: {name: 'ProductInsurance'},
        },
        {
          name: '관련 논문',
          href: {name: 'ClinicalTrialList'},
        },
        {
          name: '질환정보',
          href: {name: 'RelatedDiseaseList'},
        },
        {
          name: '보도자료',
          href: {name: 'News'},
        },
        {
          name: '관련 사이트',
          href: {name: 'ProductExternalLink'},
        },
      ];
    }
  },
}
</script>

<style scoped>

</style>
